html {
    background: #26293D;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}

/* SURBRILLANCE */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px inset rgba(0, 0, 0, 0) !important;
    box-shadow: 0 0 0 1000px inset rgba(0, 0, 0, 0) !important;
    transition: background-color 5000s ease-in-out 0s;
}

/* LOADER BAR */

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: true;
}

@property --gradX {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
}

@property --gradY {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
}

:root {
    --d: 10000ms;
    --angle: 55deg;
    --gradX: 0%;
    --gradY: 100%;
}

.loader-bar {
    border: 1px solid;
    animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
    100% {
        --angle: 420deg;
    }
}

.animation-download svg path {
    fill: #6F61F0FF !important;
}

/* Line gradient-line */

@keyframes neon-move {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

/* Switch */

.checkbox-wrapper-8 .tgl {
    display: none;
}

.checkbox-wrapper-8 .tgl,
.checkbox-wrapper-8 .tgl:after,
.checkbox-wrapper-8 .tgl:before,
.checkbox-wrapper-8 .tgl *,
.checkbox-wrapper-8 .tgl *:after,
.checkbox-wrapper-8 .tgl *:before,
.checkbox-wrapper-8 .tgl + .tgl-btn {
    box-sizing: border-box;
}

.checkbox-wrapper-8 .tgl::-moz-selection,
.checkbox-wrapper-8 .tgl:after::-moz-selection,
.checkbox-wrapper-8 .tgl:before::-moz-selection,
.checkbox-wrapper-8 .tgl *::-moz-selection,
.checkbox-wrapper-8 .tgl *:after::-moz-selection,
.checkbox-wrapper-8 .tgl *:before::-moz-selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-8 .tgl::selection,
.checkbox-wrapper-8 .tgl:after::selection,
.checkbox-wrapper-8 .tgl:before::selection,
.checkbox-wrapper-8 .tgl *::selection,
.checkbox-wrapper-8 .tgl *:after::selection,
.checkbox-wrapper-8 .tgl *:before::selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::selection {
    background: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after,
.checkbox-wrapper-8 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after {
    left: 0;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:before {
    display: none;
}

.checkbox-wrapper-8 .tgl:checked + .tgl-btn:after {
    left: 50%;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn {
    overflow: hidden;
    transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.2s ease;
    font-family: sans-serif;
    background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after,
.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
    transform: skew(10deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active {
    background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active:before {
    left: -10%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn {
    background: #6F61F0FF;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:before {
    left: -100%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:after {
    left: 0;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:active:after {
    left: 10%;
}



/* REMASTERED */
/* RTX-ON */
/* completely redone toggle and droid */

.bb8-toggle {
    --toggle-size: 6px;
    /* finally I removed the scale now everything depends on the font-size */
    /* --margin-top-for-head: 1.75em; */
    /* it's just in case 👆 */
    --toggle-width: 10.625em;
    --toggle-height: 5.625em;
    --toggle-offset: calc((var(--toggle-height) - var(--bb8-diameter)) / 2);
    --toggle-bg: linear-gradient(#2c4770, #070e2b 35%, #628cac 50% 70%, #a6c5d4)
    no-repeat;
    --bb8-diameter: 4.375em;
    --radius: 99em;
    --transition: 0.4s;
    --accent: #de7d2f;
    --bb8-bg: #fff;
}

.bb8-toggle,
.bb8-toggle *,
.bb8-toggle *::before,
.bb8-toggle *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.bb8-toggle {
    cursor: pointer;
    margin-top: var(--margin-top-for-head);
    font-size: var(--toggle-size);
}

.bb8-toggle__checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}

.bb8-toggle__container {
    width: var(--toggle-width);
    height: var(--toggle-height);
    background: var(--toggle-bg);
    background-size: 100% 11.25em;
    background-position-y: -5.625em;
    border-radius: var(--radius);
    position: relative;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.bb8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: calc(var(--toggle-offset) - 1.688em + 0.188em);
    left: var(--toggle-offset);
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
    z-index: 2;
}

.bb8__head-container {
    position: relative;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
    z-index: 2;
    -webkit-transform-origin: 1.25em 3.75em;
    -ms-transform-origin: 1.25em 3.75em;
    transform-origin: 1.25em 3.75em;
}

.bb8__head {
    overflow: hidden;
    margin-bottom: -0.188em;
    width: 2.5em;
    height: 1.688em;
    background: -o-linear-gradient(
            transparent 0.063em,
            dimgray 0.063em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.5em,
            transparent 0.5em 1.313em,
            silver 1.313em 1.438em,
            transparent 1.438em
    ),
    -o-linear-gradient(45deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(135deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(var(--bb8-bg) 1.25em, transparent 1.25em);
    background: -o-linear-gradient(
            transparent 0.063em,
            dimgray 0.063em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.5em,
            transparent 0.5em 1.313em,
            silver 1.313em 1.438em,
            transparent 1.438em
    ),
    -o-linear-gradient(45deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(135deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(var(--bb8-bg) 1.25em, transparent 1.25em);
    background: -o-linear-gradient(
            transparent 0.063em,
            dimgray 0.063em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.5em,
            transparent 0.5em 1.313em,
            silver 1.313em 1.438em,
            transparent 1.438em
    ),
    -o-linear-gradient(45deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(135deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(var(--bb8-bg) 1.25em, transparent 1.25em);
    background: -o-linear-gradient(
            transparent 0.063em,
            dimgray 0.063em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.5em,
            transparent 0.5em 1.313em,
            silver 1.313em 1.438em,
            transparent 1.438em
    ),
    -o-linear-gradient(45deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(135deg, transparent 0.188em, var(--bb8-bg) 0.188em 1.25em, transparent
            1.25em),
    -o-linear-gradient(var(--bb8-bg) 1.25em, transparent 1.25em);
    background: linear-gradient(
            transparent 0.063em,
            dimgray 0.063em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.5em,
            transparent 0.5em 1.313em,
            silver 1.313em 1.438em,
            transparent 1.438em
    ),
    linear-gradient(
            45deg,
            transparent 0.188em,
            var(--bb8-bg) 0.188em 1.25em,
            transparent 1.25em
    ),
    linear-gradient(
            -45deg,
            transparent 0.188em,
            var(--bb8-bg) 0.188em 1.25em,
            transparent 1.25em
    ),
    linear-gradient(var(--bb8-bg) 1.25em, transparent 1.25em);
    border-radius: var(--radius) var(--radius) 0 0;
    position: relative;
    z-index: 1;
    -webkit-filter: drop-shadow(0 0.063em 0.125em gray);
    filter: drop-shadow(0 0.063em 0.125em gray);
}

.bb8__head::before {
    content: "";
    position: absolute;
    width: 0.563em;
    height: 0.563em;
    background: -o-radial-gradient(
            0.25em 0.375em,
            0.125em circle,
            red,
            transparent
    ),
    -o-radial-gradient(0.375em 0.188em, 0.063em circle, var(--bb8-bg) 50%, transparent
            100%),
    -o-linear-gradient(45deg, #000 0.188em, dimgray 0.313em 0.375em, #000 0.5em);
    background: -o-radial-gradient(
            0.25em 0.375em,
            0.125em circle,
            red,
            transparent
    ),
    -o-radial-gradient(0.375em 0.188em, 0.063em circle, var(--bb8-bg) 50%, transparent
            100%),
    -o-linear-gradient(45deg, #000 0.188em, dimgray 0.313em 0.375em, #000 0.5em);
    background: -o-radial-gradient(
            0.25em 0.375em,
            0.125em circle,
            red,
            transparent
    ),
    -o-radial-gradient(0.375em 0.188em, 0.063em circle, var(--bb8-bg) 50%, transparent
            100%),
    -o-linear-gradient(45deg, #000 0.188em, dimgray 0.313em 0.375em, #000 0.5em);
    background: -o-radial-gradient(
            0.25em 0.375em,
            0.125em circle,
            red,
            transparent
    ),
    -o-radial-gradient(0.375em 0.188em, 0.063em circle, var(--bb8-bg) 50%, transparent
            100%),
    -o-linear-gradient(45deg, #000 0.188em, dimgray 0.313em 0.375em, #000 0.5em);
    background: radial-gradient(
            0.125em circle at 0.25em 0.375em,
            red,
            transparent
    ),
    radial-gradient(
            0.063em circle at 0.375em 0.188em,
            var(--bb8-bg) 50%,
            transparent 100%
    ),
    linear-gradient(45deg, #000 0.188em, dimgray 0.313em 0.375em, #000 0.5em);
    border-radius: var(--radius);
    top: 0.413em;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    -webkit-box-shadow: 0 0 0 0.089em lightgray, 0.563em 0.281em 0 -0.148em,
    0.563em 0.281em 0 -0.1em var(--bb8-bg), 0.563em 0.281em 0 -0.063em;
    box-shadow: 0 0 0 0.089em lightgray, 0.563em 0.281em 0 -0.148em,
    0.563em 0.281em 0 -0.1em var(--bb8-bg), 0.563em 0.281em 0 -0.063em;
    z-index: 1;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.bb8__head::after {
    content: "";
    position: absolute;
    bottom: 0.375em;
    left: 0;
    width: 100%;
    height: 0.188em;
    background: -o-linear-gradient(
            left,
            var(--accent) 0.125em,
            transparent 0.125em 0.188em,
            var(--accent) 0.188em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.938em,
            transparent 0.938em 1em,
            var(--accent) 1em 1.125em,
            transparent 1.125em 1.875em,
            var(--accent) 1.875em 2em,
            transparent 2em 2.063em,
            var(--accent) 2.063em 2.25em,
            transparent 2.25em 2.313em,
            var(--accent) 2.313em 2.375em,
            transparent 2.375em 2.438em,
            var(--accent) 2.438em
    );
    background: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(0.125em, var(--accent)),
            color-stop(0.125em, transparent),
            color-stop(0.188em, var(--accent)),
            color-stop(0.313em, transparent),
            color-stop(0.375em, var(--accent)),
            color-stop(0.938em, transparent),
            color-stop(1em, var(--accent)),
            color-stop(1.125em, transparent),
            color-stop(1.875em, var(--accent)),
            color-stop(2em, transparent),
            color-stop(2.063em, var(--accent)),
            color-stop(2.25em, transparent),
            color-stop(2.313em, var(--accent)),
            color-stop(2.375em, transparent),
            color-stop(2.438em, var(--accent))
    );
    background: linear-gradient(
            to right,
            var(--accent) 0.125em,
            transparent 0.125em 0.188em,
            var(--accent) 0.188em 0.313em,
            transparent 0.313em 0.375em,
            var(--accent) 0.375em 0.938em,
            transparent 0.938em 1em,
            var(--accent) 1em 1.125em,
            transparent 1.125em 1.875em,
            var(--accent) 1.875em 2em,
            transparent 2em 2.063em,
            var(--accent) 2.063em 2.25em,
            transparent 2.25em 2.313em,
            var(--accent) 2.313em 2.375em,
            transparent 2.375em 2.438em,
            var(--accent) 2.438em
    );
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.bb8__antenna {
    position: absolute;
    -webkit-transform: translateY(-90%);
    -ms-transform: translateY(-90%);
    transform: translateY(-90%);
    width: 0.059em;
    border-radius: var(--radius) var(--radius) 0 0;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.bb8__antenna:nth-child(1) {
    height: 0.938em;
    right: 0.938em;
    background: -o-linear-gradient(#000 0.188em, silver 0.188em);
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0.188em, #000),
            color-stop(0.188em, silver)
    );
    background: linear-gradient(#000 0.188em, silver 0.188em);
}

.bb8__antenna:nth-child(2) {
    height: 0.375em;
    left: 50%;
    -webkit-transform: translate(-50%, -90%);
    -ms-transform: translate(-50%, -90%);
    transform: translate(-50%, -90%);
    background: silver;
}

.bb8__body {
    width: 4.375em;
    height: 4.375em;
    background: var(--bb8-bg);
    border-radius: var(--radius);
    position: relative;
    overflow: hidden;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
    z-index: 1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: -webkit-gradient(
            linear,
            right top,
            left top,
            color-stop(4%, var(--bb8-bg)),
            color-stop(4%, var(--accent)),
            color-stop(10%, transparent),
            color-stop(90%, var(--accent)),
            color-stop(96%, var(--bb8-bg))
    ),
    -webkit-gradient(linear, left top, left bottom, color-stop(4%, var(--bb8-bg)), color-stop(4%, var(--accent)), color-stop(10%, transparent), color-stop(90%, var(--accent)), color-stop(96%, var(--bb8-bg))),
    -webkit-gradient(linear, left top, right top, color-stop(2.156em, transparent), color-stop(2.156em, silver), color-stop(2.188em, transparent)),
    -webkit-gradient(linear, left top, left bottom, color-stop(2.156em, transparent), color-stop(2.156em, silver), color-stop(2.188em, transparent));
    background: -o-linear-gradient(
            right,
            var(--bb8-bg) 4%,
            var(--accent) 4% 10%,
            transparent 10% 90%,
            var(--accent) 90% 96%,
            var(--bb8-bg) 96%
    ),
    -o-linear-gradient(var(--bb8-bg) 4%, var(--accent) 4% 10%, transparent 10%
            90%, var(--accent) 90% 96%, var(--bb8-bg) 96%),
    -o-linear-gradient(left, transparent 2.156em, silver 2.156em 2.219em, transparent
            2.188em),
    -o-linear-gradient(transparent 2.156em, silver 2.156em 2.219em, transparent
            2.188em);
    background: linear-gradient(
            -90deg,
            var(--bb8-bg) 4%,
            var(--accent) 4% 10%,
            transparent 10% 90%,
            var(--accent) 90% 96%,
            var(--bb8-bg) 96%
    ),
    linear-gradient(
            var(--bb8-bg) 4%,
            var(--accent) 4% 10%,
            transparent 10% 90%,
            var(--accent) 90% 96%,
            var(--bb8-bg) 96%
    ),
    linear-gradient(
            to right,
            transparent 2.156em,
            silver 2.156em 2.219em,
            transparent 2.188em
    ),
    linear-gradient(
            transparent 2.156em,
            silver 2.156em 2.219em,
            transparent 2.188em
    );
    background-color: var(--bb8-bg);
}

.bb8__body::after {
    content: "";
    bottom: 1.5em;
    left: 0.563em;
    position: absolute;
    width: 0.188em;
    height: 0.188em;
    background: rgb(236, 236, 236);
    color: rgb(236, 236, 236);
    border-radius: 50%;
    -webkit-box-shadow: 0.875em 0.938em, 0 -1.25em, 0.875em -2.125em,
    2.125em -2.125em, 3.063em -1.25em, 3.063em 0, 2.125em 0.938em;
    box-shadow: 0.875em 0.938em, 0 -1.25em, 0.875em -2.125em, 2.125em -2.125em,
    3.063em -1.25em, 3.063em 0, 2.125em 0.938em;
}

.bb8__body::before {
    content: "";
    width: 2.625em;
    height: 2.625em;
    position: absolute;
    border-radius: 50%;
    z-index: 0.1;
    overflow: hidden;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 0.313em solid var(--accent);
    background: -o-radial-gradient(
            center,
            1em circle,
            rgb(236, 236, 236) 50%,
            transparent 51%
    ),
    -o-radial-gradient(center, 1.25em circle, var(--bb8-bg) 50%, transparent 51%),
    -o-linear-gradient(right, transparent 42%, var(--accent) 42% 58%, transparent
            58%),
    -o-linear-gradient(var(--bb8-bg) 42%, var(--accent) 42% 58%, var(--bb8-bg)
            58%);
    background: -o-radial-gradient(
            center,
            1em circle,
            rgb(236, 236, 236) 50%,
            transparent 51%
    ),
    -o-radial-gradient(center, 1.25em circle, var(--bb8-bg) 50%, transparent 51%),
    -o-linear-gradient(right, transparent 42%, var(--accent) 42% 58%, transparent
            58%),
    -o-linear-gradient(var(--bb8-bg) 42%, var(--accent) 42% 58%, var(--bb8-bg)
            58%);
    background: radial-gradient(
            1em circle at center,
            rgb(236, 236, 236) 50%,
            transparent 51%
    ),
    radial-gradient(1.25em circle at center, var(--bb8-bg) 50%, transparent 51%),
    -webkit-gradient(linear, right top, left top, color-stop(42%, transparent), color-stop(42%, var(--accent)), color-stop(58%, transparent)),
    -webkit-gradient(linear, left top, left bottom, color-stop(42%, var(--bb8-bg)), color-stop(42%, var(--accent)), color-stop(58%, var(--bb8-bg)));
    background: radial-gradient(
            1em circle at center,
            rgb(236, 236, 236) 50%,
            transparent 51%
    ),
    radial-gradient(1.25em circle at center, var(--bb8-bg) 50%, transparent 51%),
    linear-gradient(
            -90deg,
            transparent 42%,
            var(--accent) 42% 58%,
            transparent 58%
    ),
    linear-gradient(var(--bb8-bg) 42%, var(--accent) 42% 58%, var(--bb8-bg) 58%);
}

.artificial__hidden {
    position: absolute;
    border-radius: inherit;
    inset: 0;
    pointer-events: none;
    overflow: hidden;
}

.bb8__shadow {
    content: "";
    width: var(--bb8-diameter);
    height: 20%;
    border-radius: 50%;
    background: #3a271c;
    -webkit-box-shadow: 0.313em 0 3.125em #3a271c;
    box-shadow: 0.313em 0 3.125em #3a271c;
    opacity: 0.25;
    position: absolute;
    bottom: 0;
    left: calc(var(--toggle-offset) - 0.938em);
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
    -webkit-transform: skew(-70deg);
    -ms-transform: skew(-70deg);
    transform: skew(-70deg);
    z-index: 1;
}

.bb8-toggle__scenery {
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    position: relative;
    border-radius: inherit;
}

.bb8-toggle__scenery::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 30%;
    bottom: 0;
    background: #b18d71;
    z-index: 1;
}

.bb8-toggle__cloud {
    z-index: 1;
    position: absolute;
    border-radius: 50%;
}

.bb8-toggle__cloud:nth-last-child(1) {
    width: 0.875em;
    height: 0.625em;
    -webkit-filter: blur(0.125em) drop-shadow(0.313em 0.313em #ffffffae)
    drop-shadow(-0.625em 0 #fff) drop-shadow(-0.938em -0.125em #fff);
    filter: blur(0.125em) drop-shadow(0.313em 0.313em #ffffffae)
    drop-shadow(-0.625em 0 #fff) drop-shadow(-0.938em -0.125em #fff);
    right: 1.875em;
    top: 2.813em;
    background: -o-linear-gradient(bottom left, #ffffffae, #ffffffae);
    background: -webkit-gradient(
            linear,
            left bottom,
            right top,
            from(#ffffffae),
            to(#ffffffae)
    );
    background: linear-gradient(to top right, #ffffffae, #ffffffae);
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.bb8-toggle__cloud:nth-last-child(2) {
    top: 0.625em;
    right: 4.375em;
    width: 0.875em;
    height: 0.375em;
    background: #dfdedeae;
    -webkit-filter: blur(0.125em) drop-shadow(-0.313em -0.188em #e0dfdfae)
    drop-shadow(-0.625em -0.188em #bbbbbbae) drop-shadow(-1em 0.063em #cfcfcfae);
    filter: blur(0.125em) drop-shadow(-0.313em -0.188em #e0dfdfae)
    drop-shadow(-0.625em -0.188em #bbbbbbae) drop-shadow(-1em 0.063em #cfcfcfae);
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.bb8-toggle__cloud:nth-last-child(3) {
    top: 1.25em;
    right: 0.938em;
    width: 0.875em;
    height: 0.375em;
    background: #ffffffae;
    -webkit-filter: blur(0.125em) drop-shadow(0.438em 0.188em #ffffffae)
    drop-shadow(-0.625em 0.313em #ffffffae);
    filter: blur(0.125em) drop-shadow(0.438em 0.188em #ffffffae)
    drop-shadow(-0.625em 0.313em #ffffffae);
    -webkit-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;
}

.gomrassen,
.hermes,
.chenini {
    position: absolute;
    border-radius: var(--radius);
    background: -o-linear-gradient(#fff, #6e8ea2);
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#fff),
            to(#6e8ea2)
    );
    background: linear-gradient(#fff, #6e8ea2);
    top: 100%;
}

.gomrassen {
    left: 0.938em;
    width: 1.875em;
    height: 1.875em;
    -webkit-box-shadow: 0 0 0.188em #ffffff52, 0 0 0.188em #6e8ea24b;
    box-shadow: 0 0 0.188em #ffffff52, 0 0 0.188em #6e8ea24b;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.gomrassen::before,
.gomrassen::after {
    content: "";
    position: absolute;
    border-radius: inherit;
    -webkit-box-shadow: inset 0 0 0.063em rgb(140, 162, 169);
    box-shadow: inset 0 0 0.063em rgb(140, 162, 169);
    background: rgb(184, 196, 200);
}

.gomrassen::before {
    left: 0.313em;
    top: 0.313em;
    width: 0.438em;
    height: 0.438em;
}

.gomrassen::after {
    width: 0.25em;
    height: 0.25em;
    left: 1.25em;
    top: 0.75em;
}

.hermes {
    left: 3.438em;
    width: 0.625em;
    height: 0.625em;
    -webkit-box-shadow: 0 0 0.125em #ffffff52, 0 0 0.125em #6e8ea24b;
    box-shadow: 0 0 0.125em #ffffff52, 0 0 0.125em #6e8ea24b;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.chenini {
    left: 4.375em;
    width: 0.5em;
    height: 0.5em;
    -webkit-box-shadow: 0 0 0.125em #ffffff52, 0 0 0.125em #6e8ea24b;
    box-shadow: 0 0 0.125em #ffffff52, 0 0 0.125em #6e8ea24b;
    -webkit-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;
}

.tatto-1,
.tatto-2 {
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    border-radius: var(--radius);
}

.tatto-1 {
    background: #fefefe;
    right: 3.125em;
    top: 0.625em;
    -webkit-box-shadow: 0 0 0.438em #fdf4e1;
    box-shadow: 0 0 0.438em #fdf4e1;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.tatto-2 {
    background: -o-linear-gradient(#e6ac5c, #d75449);
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#e6ac5c),
            to(#d75449)
    );
    background: linear-gradient(#e6ac5c, #d75449);
    right: 1.25em;
    top: 2.188em;
    -webkit-box-shadow: 0 0 0.438em #e6ad5c3d, 0 0 0.438em #d755494f;
    box-shadow: 0 0 0.438em #e6ad5c3d, 0 0 0.438em #d755494f;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}

.bb8-toggle__star {
    position: absolute;
    width: 0.063em;
    height: 0.063em;
    background: #fff;
    border-radius: var(--radius);
    -webkit-filter: drop-shadow(0 0 0.063em #fff);
    filter: drop-shadow(0 0 0.063em #fff);
    color: #fff;
    top: 100%;
}

.bb8-toggle__star:nth-child(1) {
    left: 3.75em;
    -webkit-box-shadow: 1.25em 0.938em, -1.25em 2.5em, 0 1.25em, 1.875em 0.625em,
    -3.125em 1.875em, 1.25em 2.813em;
    box-shadow: 1.25em 0.938em, -1.25em 2.5em, 0 1.25em, 1.875em 0.625em,
    -3.125em 1.875em, 1.25em 2.813em;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.bb8-toggle__star:nth-child(2) {
    left: 4.688em;
    -webkit-box-shadow: 0.625em 0, 0 0.625em, -0.625em -0.625em, 0.625em 0.938em,
    -3.125em 1.25em, 1.25em -1.563em;
    box-shadow: 0.625em 0, 0 0.625em, -0.625em -0.625em, 0.625em 0.938em,
    -3.125em 1.25em, 1.25em -1.563em;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.bb8-toggle__star:nth-child(3) {
    left: 5.313em;
    -webkit-box-shadow: -0.625em -0.625em, -2.188em 1.25em, -2.188em 0,
    -3.75em -0.625em, -3.125em -0.625em, -2.5em -0.313em, 0.75em -0.625em;
    box-shadow: -0.625em -0.625em, -2.188em 1.25em, -2.188em 0, -3.75em -0.625em,
    -3.125em -0.625em, -2.5em -0.313em, 0.75em -0.625em;
    -webkit-transition: var(--transition);
    -o-transition: var(--transition);
    transition: var(--transition);
}

.bb8-toggle__star:nth-child(4) {
    left: 1.875em;
    width: 0.125em;
    height: 0.125em;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.bb8-toggle__star:nth-child(5) {
    left: 5em;
    width: 0.125em;
    height: 0.125em;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.bb8-toggle__star:nth-child(6) {
    left: 2.5em;
    width: 0.125em;
    height: 0.125em;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}

.bb8-toggle__star:nth-child(7) {
    left: 3.438em;
    width: 0.125em;
    height: 0.125em;
    -webkit-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;
}

/* actions */

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(1) {
    top: 0.625em;
}

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(2) {
    top: 1.875em;
}

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(3) {
    top: 1.25em;
}

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(4) {
    top: 3.438em;
}

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(5) {
    top: 3.438em;
}

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(6) {
    top: 0.313em;
}

.bb8-toggle__checkbox:checked
+ .bb8-toggle__container
.bb8-toggle__star:nth-child(7) {
    top: 1.875em;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .bb8-toggle__cloud {
    right: -100%;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .gomrassen {
    top: 0.938em;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .hermes {
    top: 2.5em;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .chenini {
    top: 2.75em;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container {
    background-position-y: 0;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .tatto-1 {
    top: 100%;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .tatto-2 {
    top: 100%;
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .bb8 {
    left: calc(100% - var(--bb8-diameter) - var(--toggle-offset));
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .bb8__shadow {
    left: calc(100% - var(--bb8-diameter) - var(--toggle-offset) + 0.938em);
    -webkit-transform: skew(70deg);
    -ms-transform: skew(70deg);
    transform: skew(70deg);
}

.bb8-toggle__checkbox:checked + .bb8-toggle__container .bb8__body {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(225deg);
}

.bb8-toggle__checkbox:hover + .bb8-toggle__container .bb8__head::before {
    left: 100%;
}

.bb8-toggle__checkbox:not(:checked):hover
+ .bb8-toggle__container
.bb8__antenna:nth-child(1) {
    right: 1.5em;
}

.bb8-toggle__checkbox:hover
+ .bb8-toggle__container
.bb8__antenna:nth-child(2) {
    left: 0.938em;
}

.bb8-toggle__checkbox:hover + .bb8-toggle__container .bb8__head::after {
    background-position: 1.375em 0;
}

.bb8-toggle__checkbox:checked:hover
+ .bb8-toggle__container
.bb8__head::before {
    left: 0;
}

.bb8-toggle__checkbox:checked:hover
+ .bb8-toggle__container
.bb8__antenna:nth-child(2) {
    left: calc(100% - 0.938em);
}

.bb8-toggle__checkbox:checked:hover + .bb8-toggle__container .bb8__head::after {
    background-position: -1.375em 0;
}

.bb8-toggle__checkbox:active + .bb8-toggle__container .bb8__head-container {
    -webkit-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
}

.bb8-toggle__checkbox:checked:active
+ .bb8-toggle__container
.bb8__head-container {
    -webkit-transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    transform: rotate(-25deg);
}

.bb8:hover .bb8__head::before,
.bb8:hover .bb8__antenna:nth-child(2) {
    left: 50% !important;
}

.bb8:hover .bb8__antenna:nth-child(1) {
    right: 0.938em !important;
}

.bb8:hover .bb8__head::after {
    background-position: 0 0 !important;
}


/* Toggle Switch */

/* To hide the checkbox */
.checkboxInput {
    display: none;
}

.toggleSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 30px;
    background-color: rgb(82, 82, 82);
    border-radius: 20px;
    cursor: pointer;
    transition-duration: .2s;
}

.toggleSwitch::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    left: 5px;
    background-color: transparent;
    border-radius: 50%;
    transition-duration: .2s;
    box-shadow: 5px 2px 7px rgba(8, 8, 8, 0.26);
    border: 5px solid white;
}

.checkboxInput:checked+.toggleSwitch::after {
    transform: translateX(100%);
    transition-duration: .2s;
    background-color: white;
}
/* Switch background change */
.checkboxInput:checked+.toggleSwitch {
    background-color: #6358d0;
    transition-duration: .2s;
}


/* Toggle Switch */

.checkbox-wrapper-12 {
    position: relative;
}

.checkbox-wrapper-12 > svg {
    position: absolute;
    top: -130%;
    left: -170%;
    width: 110px;
    pointer-events: none;
}

.checkbox-wrapper-12 * {
    box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
    outline: 0;
}

.checkbox-wrapper-12 .cbx {
    width: 24px;
    height: 24px;
    top: calc(100px - 12px);
    left: calc(100px - 12px);
}

.checkbox-wrapper-12 .cbx input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #bfbfc0;
    border-radius: 50%;
}

.checkbox-wrapper-12 .cbx label {
    width: 24px;
    height: 24px;
    background: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: trasnlate3d(0, 0, 0);
    pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
    pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 19;
    stroke-dashoffset: 19;
    transition: stroke-dashoffset 0.3s ease;
    transition-delay: 0.2s;
}

.checkbox-wrapper-12 .cbx input:checked + label {
    animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked + label + svg path {
    stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
    40% {
        background: #866efb;
        box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }

    100% {
        background: #866efb;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

@-webkit-keyframes splash-12 {
    40% {
        background: #866efb;
        box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }

    100% {
        background: #866efb;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

@-o-keyframes splash-12 {
    40% {
        background: #866efb;
        box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }

    100% {
        background: #866efb;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}

@keyframes splash-12 {
    40% {
        background: #866efb;
        box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }

    100% {
        background: #866efb;
        box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
}


.tfa-pin-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.tfa-pin-input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #676a80;
    border-radius: 5px;
    background: #333;
    color: #fff;
}

.tfa-pin-input:focus {
    border-color: #6358d0;
    outline: none;
}